import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Menu from '../Menu/Menu'
import { TypeImageGrid, TypeModalBlock, TypeResourceSet } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import { isContentfulType } from '../../lib/contentful'

type ContentModalBlockProps = {
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  entry?: TypeModalBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const transformContent = (
  entry: TypeModalBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>,
  closeModal: () => void
) => {
  return {
    ...entry.fields.content,
    content: entry?.fields?.content?.content?.map(block => {
      if (
        isContentfulType<TypeImageGrid<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
          block?.data?.target,
          'imageGrid'
        )
      ) {
        const imageGrid = block?.data?.target
        const updatedImageGridItems = imageGrid?.fields?.imageGridItems?.map(item => {
          if (item?.fields?.slug === 'close-modal') {
            return {
              ...item,
              fields: {
                ...item.fields,
                onClick: closeModal,
                slug: undefined,
              },
            }
          }
          return item
        })

        return {
          ...block,
          data: {
            ...block.data,
            target: {
              ...imageGrid,
              fields: {
                ...imageGrid.fields,
                imageGridItems: updatedImageGridItems,
              },
            },
          },
        }
      }
      return block
    }),
  }
}

const ContentModalBlock = ({ menu, entry }: ContentModalBlockProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPaused, setIsPaused] = useState(false)

  const closeModal = useCallback(() => {
    sessionStorage.setItem('aex-splash-modal', 'closed')
    setIsOpen(false)
  }, [])
  const togglePause = () => setIsPaused(!isPaused)

  useEffect(() => {
    const isClosed = sessionStorage.getItem('aex-splash-modal') === 'closed'
    if (!isClosed) {
      setIsOpen(true)
    }
    return () => {
      // close modal on unmount
      sessionStorage.setItem('aex-splash-modal', 'closed')
    }
  }, [])

  const content = useMemo(() => transformContent(entry, closeModal), [entry, closeModal])

  if (!isOpen) {
    return null
  }

  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-white bg-no-repeat bg-cover bg-center flex flex-col  items-center z-[500] overscroll-contain overflow-auto"
      onClick={togglePause}
    >
      {menu && (
        <Menu menu={menu} theme="AEX Splash" stationary hideBurgerMenu logoOnClick={closeModal} />
      )}

      {<div>{renderDocumentToReactComponents(content)}</div>}
    </div>
  )
}

export default ContentModalBlock
